/* eslint-disable max-len */
// REPEATED WITH EVERY BANNER PRODUCT

export const commonText = [
  {
    article: `**Pozostałe rodzaje dostępnych materiałów banerów**
    \n * **Baner Blockout** - charakteryzuje się on specjalną czarną przekładką w środku medium, która powoduje że materiał ten nie przepuszcza światła. Jest on również bardziej trwały na przedarcia niż baner standard laminowany. 
    Posiada on również strukturę powierzchni umożliwiającą wydruk po obu stronach nośnika, co daje możliwość wydruku jedno lub dwustronnego. Gramatura: 440g/m2. Najczęstsze zastosowanie: banery dwustronne, ścianki wystawiennicze, roll-up’y, tła nieprzepuszczające światła.
    \n **Baner Blockout cena - 60,00 zł/m2**
    \n * **Baner Backlit** - charakteryzuje się on właściwościami które umożliwiają przepuszczanie i zadowalające rozpraszanie światła, Jego parametry powodują że wydruk na nim jest doskonale nasycony, a barwy dobrze odwzorowane. Gramatura: 510g/m2. Najczęstsze zastosowanie: kasetony i reklamy podświetlane.
    \n **Baner Backlit cena - 65,00 zł/m2**
    \n Podane ceny są cenami netto. Cena zawiera wydruk oraz wykończenie banera.
    `,
  },
  {
    article: `**Jak poprawnie przygotować plik do druku banera?**
    \n * Plik zapisany w formacie JPEG, TIF lub PDF,
    \n * Plik przygotowany w palecie kolorów CMYK,
    \n * W przypadku formatu rastrowego rozdzielczość projektu powinna wynosić nie mniej niż 70 dpi
    Należy pamiętać aby przy skalowaniu projektu rozdzielczość była proporcjonalnie większa np. jeśli przesłany projekt jest w skali 1:2, wtedy rozdzielczość powinna wynosić 300 dpi. W przypadku formatu wektorowego należy pamiętać ,aby plik miał zachowane właściwego proporcje docelowego wymiaru.
    \n * Projekt bez żadnych dodatkowych spadów,
    \n * Należy przyjąć w projekcie min. 5 cm marginesu od krawędzi.
    \n Aby uzyskać głęboką czerń sugerujemy ustawienie składowych CMYK: 100 - 40 - 40 - 40.
    `,
  },
  // {
  //   article: `## Jak zaprojektować <span class="orange">baner reklamowy</span>, żeby zwrócić uwagę?
  //   \n Nie ma jednej skutecznej metody na wykonanie projektu, który przynosiłbym zadowalający efekt. Należy jednak pamiętać o kilku kluczowych kwestiach. Po pierwsze postarajmy się, aby nasz układ reklamy był spójny, a kreacja estetyczna. Podstawowe rzeczy takie jak niezachowane wewnętrzne marginesy bezpieczeństwa, chaotycznie sformatowany tekst i znaki interpunkcyjne, nieczytelny font czy niedbale wyretuszowane grafiki znacznie obniżają jakość reklamy i wpływają że ich odbiór staje się słabszy. To o co trzeba zadbać to dobrze dobrany layout, ewentualne zdjęcia i sprecyzowana treść znacznie poprawi jej efektywność. W zależności od tego jaki cel ma spełniać **baner reklamowy**, informacyjny czy wizerunkowy, takie też powinniśmy wyznaczyć kryteria projektu. Przy banerze reklamowym / promocyjnym w szczególności powinniśmy zadbać o to, aby dobrze widoczne były najistotniejsze elementy czyli np. promowany produkt, cena itp., przy banerze informacyjny najważniejsza jest jasność przekazu i jego czytelność, a w banerze wizerunkowym zadbajmy, aby cała kreacja projektu była atrakcyjna oraz zawarte w niej treści i hasła były odpowiednie.
  //   \n Jeśli potrzebują Państwo wykonać **projekt graficzny** zachęcamy do skorzystania z naszych usług :) -> **projekt graficzny**
  //   `,
  // },
  // {
  //   article: `## Czy używanie <span class="orange">banerów reklamowych</span> jest korzystne?
  //   \n **Druk banerów** to relatywnie niskie koszty kampani w porównaniu z innymi formami reklamy co stanowi że jest to nadal atrakcyjny środek przekazu. Jeśli reklama zostanie dobrze zaprojektowania i będzie dobrze umiejscowiona to nie dużym budżetem jesteśmy w stanie dotrzeć do masowej liczby odbiorców, z których część może zainteresować naszą oferta czy też wizerunek naszej firmy może zapaść w pamięci potencjalnego Klienta.
  //   `,
  // },
  // {
  //   article: `## O czym <span class="orange">warto pamiętać?</span>
  //   \n Wykorzystanie **banerów reklamowych** jest w dzisiejszych czasach bardzo częste. Jest to doskonały nośnik reklamy, zarówno w formie wewnętrznej, jak i zewnętrznej. Takie banery można ulokować w różnego rodzaju miejscach, są one dla odbiorców doskonale widoczne. Banery tworzone są ze specjalnych materiałów, odpornych na różnorodne uszkodzenia zewnętrzne, aby ich funkcjonalność była jak największa. Z tego też powodu mają uniwersalne zastosowanie. Drukowanie banerów odbywa się na podstawie projektów dostarczonych przez klientów, którym zależy na tym, aby w posiadaniu takich banerów się znaleźć. Oczywiście mogą mieć one bardzo zróżnicowane wymiary, w zależności od potrzeb Klientów. Doskonale znamy na drukowaniu takich materiałów reklamowych oraz korzystamy z nowoczesnych maszyn, dzięki czemu wydruki są precyzyjne i bardzo czytelne.
  //   `,
  // },
  // {
  //   article: `## Banery <span class="orange">wielkoformatowe</span>
  //   \n Klientom oferowane są przede wszystkim **banery wielkoformatowe**, które są doskonale widoczne dla każdego odbiorcy. **Druk banerów** tego typu zajmuje jednak dużo więcej czasu i pochłania więcej materiałów, jednak jest to dla wielu osób korzystne rozwiązanie, jeśli chce się korzystnie zareklamować swoją osobę, czy też realizowane usługi firmowe. **Drukowane banery reklamowe** mają w sobie wiele zalet, ponieważ można je umieścić w różnych pomieszczeniach oraz na posesjach, czy też ogrodzeniach, tak aby jak największa grupa osób miała do nich dostęp. To od Klienta zależy to, jaki konkretnie tekst na takich banerach będzie widniał, więc warto pomyśleć trochę nad hasłem reklamowym umieszczonym na bauerze, tak aby było ono chwytliwe. Nie wolno też zapomnieć o danych kontaktowych do firmy, która reklamuje się na banerze. Klienci mają jednak pewność tego, że wydruki na banerach będą precyzyjne, doskonale widoczne, a do tego baner będzie posiadał optymalne wymiary i na pewno będzie łatwo go zauważyć.
  //   `,
  // },
  // {
  //   article: `## Banery <span class="orange">z różnymi treściami reklamowymi</span>
  //   \n **Baner drukarnia** umożliwia umieszczanie zróżnicowanych treści reklamowych na takich banerach. Nie jest powiedziane więc to, że kolejne banery muszą zawierać w sobie takie treści jak do tej pory miało to miejsce. Jeśli klient zauważy, że jego reklama nie spełnia swojej roli, może wymyślić coś innego, aby szansa na sukces była jeszcze większa. Oczywiście pracownicy drukarni mu w tym pomogą, wymyślając dla niego kreatywne rozwiązania, na które będzie można się zdecydować w zamian za niewielkie opłaty. Niektóre osoby decydują się na małe banery, za to drukowane w bardzo dużych ilościach. Jest to jakieś rozwiązanie, jednak warto wydrukować mniej banerów o większych rozmiarach i umieścić je w centrum miasta, powinny one tam wisieć minimum przez kilka miesięcy. Dzięki temu taki baner reklamowy zapadnie w pamięć okolicznym mieszkańcom i jest szansa, że po pewnym czasie zdecydują się na skorzystanie z reklamowanych usług. O montaż takich banerów również nie trzeba się martwić, ponieważ umiejscowienie ich na ogrodzeniach, czy też na szybach nie zajmuje dużo czasu.
  //   `,
  // },
  // {
  //   article: `## Projektowanie <span class="orange">banerów reklamowych</span> oraz ceny
  //   \n **Projektowanie banerów reklamowych** nie należy do najłatwiejszych czynności, jednak zajmują się tym kompetentne osoby, które wiedzą o tym dużo i bez problemów wykonają każdy projekt na życzenie klienta. Na banerach mogą znajdować się więc nie tylko słowa w postaci hasła reklamowego, ale też różne znaki graficzne. Wszystko trzeba sobie dokładnie przemyśleć, aby reklama okazała się być wyjątkowo trafna. W dowolnym momencie można sobie przeanalizować banery reklamowe cennik, aby móc stwierdzić czy taka oferta jest korzystna dla klienta pod kątem finansowym. Na pewno jest to opłacalne rozwiązanie dla osób i firm, którym zależy na tym, aby udanie rozreklamować własną działalność małym nakładem sił. Wykonanie takiego projektu kosztuje średnio 100 złotych, natomiast za każdą poprawkę na banerze zapłaci się małe pieniądze. Z kolei wydrukowanie baneru o stosunkowo dużych rozmiarach kosztować będzie około 200 złotych. **Baner reklamowy cena** jest więc dla klientów korzystna, a korzyści z takiej reklamy są nieocenione.
  //   `,
  // },
  // {
  //   article: `## Dla kogo <span class="orange">banery reklamowe?</span>
  //   \n **Reklamowy baner** przeznaczony jest przede wszystkim dla osób, które prowadzą własną firmę i założyły ją stosunkowo niedawno. Jest  to najlepsza opcja na pozyskanie klientów dla własnej firmy, aby rozwinąć działalność i przestać martwić się o zyski. **Banery tanio** wydrukować można w odpowiednich dla siebie ilościach. Takie banery przydadzą się też firmom narzekającym na brak nowych klientów, które od dawna znajdują się na rynku i są mało konkurencyjne. Inwestycja w baner może odmienić taką sytuację, więc lepiej się przed tym nie wzbraniać, tylko jak najszybciej zdecydować się na zamówienie banerów o odpowiednich wymiarach. Z banerów korzystają też osoby indywidualne, np. politycy przed ważnymi dla siebie wyborami. Jest to szansa na dotarcie z programem wyborczym do szerszej grupy osób, a to będzie oznaczało możliwość uzyskania większej ilości głosów podczas wyborów, dlatego zapotrzebowanie na banery jest naprawdę duże.
  //   `,
  // },
];

export const priceListHint = 'Cena za baner w pełni wykończony, tj. zgrzane i zaoczkowane krawędzie. Podane ceny są cenami netto. Cena nie zawiera kosztów wysyłki.';
export const slogan = '**<span class="center">Zamów nie tylko <span class="orange">baner sprzedam. Tanie banery</span></span>**';
