export const pageTitle = 'Banery reklamowe powlekane';
export const pageDescription = 'Oferujemy wielkoformatowy druk banerów powlekanych na zamówienie. Świetnie sprawdzą się jako reklama zamontowana na ogrodzeniu, balkonie lub ścianie.';
export const mainHeader = 'Baner reklamowy powlekany';
export const title = 'Baner powlekany';

export const bannerInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**${title} <span class="orange">czas realizacji</span>**
      \n 2-3 dni robocze`,
    },
    {
      article: `**${title} <span class="orange">koszt wysyłki</span>**
      \n 30,00 zł/netto. W jednej paczce zmieści się ok. 30m2 banera.`,
    },
    {
      article: `**${title} <span class="orange">wymiar</span>**
      \n Maksymalna możliwa szerokość banera w jednym kawałku to 500cm. Przy większych formatach banery są łączone z brytów.
      \n Najczęściej zamawiane wymiary banerów powlekanych to:
      \n * ${title} 3 x 2 m
      \n * ${title} 3 x 3 m
      \n * ${title} 4 x 2 m
      \n * ${title} 4 x 3 m
      \n * ${title} 5 x 2,4 m
      \n * ${title} 6 x 3 m
      `,
    },
  ],
  rightColumn: [
    {
      article: `**${title} <span class="orange">opis</span>**
      \n Podobnie jak baner laminowany standardowy materiał ten został stworzony w procesie zalaminowania włókien poliestrowych płynnym PCV. Od materiału standardowego różni się większą ilością włókien i przeplotów, tym samym ma on zwiększoną trwałość na rozdarcia oraz jest bardziej giętki i elastyczny. **${title}** jest mniej sztywny od standardowego laminowanego, tym samym występuje mniejsze ryzyko zagięć.
      \n Materiał o gramaturze 510g/m2 i przeplotem 1000x1000.
      \n Kolorystyka: CMYK.
      \n Rozdzielczość zadruku: 720 dpi.
      \n Wydruk wykończony poprzez zgrzanie krawędzi i zaoczkowanie co 50cm.
      Na życzenie Klienta jest możliwość dodatkowego gęstszego oczkowania.
      \n Gotowy **baner reklamowy** można zamontować poprzez oczka przy użyciu np. opasek zaciskowych tzw. trytytek czy linek/gum. Możliwe jest również wykończenie zadrukowanego banera tzw. tunelami, w które można wsunąć metalowe profile, drewniane kołki czy przeciągnąć linkę.
      \n Przeznaczony do większych rozmiarów reklamy i ekspozycji, które są bardziej narażone na silniejsze podmuchy wiatru.
      \n Druk wykonywany jest najtrwalszą techniką druku solwentowego tj. twardy solwent. Jego trwałość szacuje się na ok. kilka lat. Jest on odporny na czynniki atmosferyczne, w tym także promieniowanie UV. Do zalet tego rodzaju druku, zalicza się brak konieczności laminowania wydruków. Ponadto, cechuje go odporność na odbarwienia, wodę i ścieranie.
      \n Reklama na tego rodzaju banerze jest jednym z najtańszych sposobów na zareklamowanie swoich towarów, usług lub marki.
      `,
    },
    {
      article: `**Baner powlekany <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
